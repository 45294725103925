import * as constants from "../constants";

export const theme = {
  textStyles: {
    T1: {
      fontSize: 38,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: "#242424",
      lineHeight: 64,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    T2: {
      fontSize: 32,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 40,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H1: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H2: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H3: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B1: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 28,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B2: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B2a: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3a: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.B3A_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3b: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3B_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3d: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    CTA: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: "#ED6C2D",
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    CTAa: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B4: {
      fontSize: 12,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B5: {
      fontSize: 12,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 70,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
  },
  buttons: {
    primary: {
      default: {
        color: "#FFFFFF",
        background: "#ED6C2D",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#ED6C2D",
        padding: constants.BORDER_RADIUS_DEFAULT,
      },
      hover: {
        color: "#FFFFFF",
        background: "#FA8A53",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#FA8A53",
      },
      disabled: {
        color: "#24242440",
        background: "#0000000A",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#D9D9D9",
      },
    },
    secondary: {
      default: {
        color: "#ED6C2D",
        background: "#FFFFFF",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        borderColor: "#ED6C2D",
        fontSize: 14,
        padding: constants.BORDER_RADIUS_DEFAULT,
      },
      hover: {
        color: "#ED6C2D",
        background: "#FFFFFF",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        borderColor: "#ED6C2D",
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
      },
      disabled: {
        color: "#24242440",
        background: "#0000000A",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#D9D9D9",
      },
    },
    tertiary: {
      default: {
        color: "#FFFFFF",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
        padding: constants.BORDER_RADIUS_DEFAULT,
      },
      hover: {
        color: "#FFFFFF",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
      },
      disabled: {
        color: "#24242440",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
      },
    },
  },
  backgroundCanvas: constants.BACKGROUND_CANVAS_COLOR,
  primaryColors: {
    defaultMenu: "#6F6AF8",
    primary1: constants.PRIMARY_COLOR_1,
    primary2: constants.PRIMARY_COLOR_2,
  },
  secondaryColors: {
    secondary1: constants.SECONDARY_COLOR_1,
    secondary2: constants.SECONDARY_COLOR_2,
  },
  supportingColors: {
    supporting1: constants.SUPPORTING_COLOR_1,
    supporting2: constants.SUPPORTING_COLOR_2,
    supporting3: constants.SUPPORTING_COLOR_3,
    supporting4: constants.SUPPORTING_COLOR_4,
    supporting5: constants.SUPPORTING_COLOR_5,
  },
  baseColors: {
    base1: constants.BASE_COLOR_1,
    base2: constants.BASE_COLOR_2,
    base3: constants.BASE_COLOR_3,
  },
  breakpoints: {
    small: "768px",
    medium: "992px",
    large: "1024px",
  },
};
