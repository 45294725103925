const updateStateWithKeyAndValue = (state, key, value) => {
  return { ...state, [key]: value };
};

const concatPayload = (existingValue, payload) => {
  return Array.isArray(existingValue)
    ? [...existingValue, ...(Array.isArray(payload) ? payload : [payload])]
    : [existingValue, ...(Array.isArray(payload) ? payload : [payload])];
};

const filterDeletedFeatures = (deletedFeature, feature) => {
  if (deletedFeature?.geometry?.type === "Point") {
    return (
      ((deletedFeature?.id === feature?.id) ||
        (deletedFeature?.geometry?.coordinates?.[0] ===
          feature?.geometry?.coordinates?.[0] &&
          deletedFeature?.geometry?.coordinates?.[1] ===
          feature?.geometry?.coordinates?.[1] &&
          deletedFeature?.geometry?.type === feature?.geometry?.type
        )
      )
    );
  }
  return (
    ((deletedFeature?.id === feature?.id) ||
      (deletedFeature?.geometry?.coordinates?.[0]?.[0]?.[1] ===
        feature?.geometry?.coordinates?.[0]?.[0]?.[1] &&
        deletedFeature?.geometry?.coordinates?.[0]?.[0]?.[1] ===
        feature?.geometry.coordinates?.[0]?.[0]?.[1] &&
        deletedFeature?.geometry?.type === feature?.geometry?.type
      )
    )
  );
};

const removeMapData = (state, stateKey, payload) => {
  if (stateKey === "locationData") {
    const updatedDrawnData = (state.locationData || []).filter((feature) => {
      return !payload?.some((deletedFeature) =>
        filterDeletedFeatures(deletedFeature, feature)
      );
    });
    return updateStateWithKeyAndValue(state, stateKey, updatedDrawnData);
  } else {
    return state;
  }
};

const setMapData = (state, stateKey, payload) => {
  return updateStateWithKeyAndValue(
    state,
    stateKey,
    stateKey in state ? concatPayload(state[stateKey], payload) : payload
  );
};

const handleDefaultCase = (state, action) => {
  const { payload, key: stateKey } = action;
  if (stateKey === "addSubmission") {
    return { ...state, locationData: null };
  } else if (
    payload &&
    typeof payload === "object" &&
    Object.keys(payload).length === 1 &&
    !stateKey
  ) {
    const [key, value] = Object.entries(payload)[0];
    return { ...state, [key]: value };
  } else if (payload !== undefined) {
    return {
      ...state,
      [stateKey]: payload,
    };
  }
  return state;
};

const reducer = (state, { payload, key: stateKey, type }) => {
  switch (type) {
    case "RESET_MAP_DATA":
      return { ...state, locationData: payload };
    case "REMOVE_MAP_DATA":
      return removeMapData(state, stateKey, payload);
    case "SET_MAP_DATA":
      return setMapData(state, stateKey, payload);
    default:
      return handleDefaultCase(state, { payload, key: stateKey, type });
  }
};

export default reducer;
