import React, { Suspense, lazy } from "react";
import { Spin } from "antd";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { createRoot } from "react-dom/client";
import store from "./redux/store";
import { theme } from "../src/theme/ThemeConfig";
import "./index.css";
const App = lazy(() => import("./App"));

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Suspense fallback={<Spin size="large" />}>
        <App />
      </Suspense>
    </Provider>
  </ThemeProvider>
);
