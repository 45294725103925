import { createStore, applyMiddleware } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {thunk} from 'redux-thunk';
import rootReducer from './reducer';

const store = createStore(rootReducer, applyMiddleware(thunk));

export const useAppDispatch = () => useDispatch();
export const useAppSelector = (selector) => useSelector(selector); // Pass the selector function as an argument

export default store;