// Font Weights
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_SEMIBOLD = 600;

export const POPPINS_FONT_FAMILY = 'Poppins, sans-serif';

export const B3B_COLOR = "#5550F7";

export const B3A_COLOR = "#a2a0a0";

// Border Sizes
export const BORDER_SIZE_DEFAULT = '1px';

// Border Radii
export const BORDER_RADIUS_DEFAULT = '6px';

// Text Alignments
export const TEXT_ALIGNMENT_CENTER = 'center';

// Colors
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BLACK = '#000000';
export const COLOR_GREY_LIGHT = '#F0F0F0';
export const COLOR_GREY_DARK = '#242424';
export const COLOR_TRANSPARENT = '#00000000';
export const COLOR_CTA = '#ED6C2D' 

// Background Colors
export const BACKGROUND_CANVAS_COLOR = '#F8F9FB';

// Primary Colors
export const PRIMARY_DEFAULT_MENU_COLOR = '#6F6AF8';
export const PRIMARY_COLOR_1 = '#E8E7FA';
export const PRIMARY_COLOR_2 = '#F3F2FD';

// Secondary Colors
export const SECONDARY_COLOR_1 = '#ED6C2D';
export const SECONDARY_COLOR_2 = '#FFF7F5';

// Supporting Colors
export const SUPPORTING_COLOR_1 = '#6DBCB6';
export const SUPPORTING_COLOR_2 = '#FDB228';
export const SUPPORTING_COLOR_3 = '#4D88C9';
export const SUPPORTING_COLOR_4 = '#AB7D65';
export const SUPPORTING_COLOR_5 = '#EF5644';

// Base Colors
export const BASE_COLOR_1 = '#242424';
export const BASE_COLOR_2 = '#F0F0F0';
export const BASE_COLOR_3 = '#FFFCE8';